import Vue from 'vue'
import axios from 'axios'
import {
  Message
} from 'element-ui'
import Utils from '../utils'
import API from '../api'

// 创建axios实例
const service = axios.create({
  // baseURL: '/api_test', // api的base_url
  baseURL: process.env.VUE_APP_APIURL, // api的base_url
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json'
  if (Utils.Auth.isAuth()) {
    config.headers['Authorization'] = `Bearer ${Vue.ls.get('token')}`;
    return config;
  } else {
    return config;
  }
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(res => {
  return res
},
  error => {
    // console.log('err' +JSON.stringify(error)) // for debug
    // const config = response.config
    if (error.response) {
      console.log(`error.response.status:${error.response.status}`)
      switch (error.response.status) {
        case 401:
          console.log("401")
          if (error.config.url !== `oauth/access_token`) {
            return API.auth.refreshToken().then(res => {
              console.log("finish refreshToken")
              Utils.Auth.login(res.data);
              error.config.headers['Content-Type'] = 'application/json'
              error.config.headers['Authorization'] = `Bearer ${Vue.ls.get('token')}`;
              return service(error.config)
            }).catch(e => {
              //刷新token失败只能跳转到登录页重新登录
              console.log(`刷新token失败只能跳转到登录页重新登录:${e}`)
              Utils.Auth.logOut();
              // return Promise.reject(error.response.data);
              throw Promise.reject(error.response.data);
            });
          }
          else {
            console.log(`刷新token失败只能跳转到登录页重新登录2`)
            Utils.Auth.logOut();
            // return Promise.reject(error.response.data);
            throw Promise.reject(error.response.data);
          }
          break;
        case 403:
          if (error.response.data.error === "Forbidden" && error.response.data.message === "Forbidden") {
            console.log(`error.response.error:${"Forbidden"}`)
            Message({
              message: "此帳戶權限不足!",
              type: "warning",
            });
          }
          throw Promise.reject(error.response.data);
          break;
        case 500:
          Message({
            message: "請洽系統管理員或產品服務人員!",
            type: 'error'
          })
          throw Promise.reject(error.response.data);
          break;
        default:
          console.log("default")
          if (error.response.data) {
            Message({
              message: error.response.data.message,
              type: 'error'
            })
          } else {
            Message({
              message: error.message,
              type: 'error'
            })
          }

          // return Promise.reject(error.response.data);
          throw Promise.reject(error.response.data);
          break;
      }
      // 返回介面返回的錯誤資訊

    }
  }
)

export default service
