import request from './request'

function query(params) {
    return request({
        url: '/svcCustomer/query',
        method: 'post',
        data: params
    })
}

function queryById(id) {
    return request({
        url: `/svcCustomer/${id}`,
        method: 'get'
    })
}

function getCustomUbn() {
    return request({
        url: `/svcCustomer/getCustomCompanyUbn`,
        method: 'get'
    })
}

function addCustomer(data) {
    return request({
        url: `/svcCustomer/addCustomer`,
        method: 'post',
        data: data
    })
}

function update(id, data) {
    return request({
        url: `/svcCustomer/updateApi?id=${id}`,
        method: 'post',
        data: data
    })
}

// 測試外部系統連線情況[ Ezrecepit / Newebpay ]
function testExternalSysLogin(type, data) {
    return request({
        url: `/svcCustomer/testExternalSysLogin?type=${type}`,
        method: 'post',
        data: data
    })
}

// 測試外部系統 Newebpay
function testNewebpay(data) {
    return request({
        url: `/svcCustomer/testNewebpay`,
        method: 'post',
        data: data
    })
}

// 測試外部系統 Newebpay Stripe
function testNewebpayStripe(data) {
    return request({
        url: `/svcCustomer/testNewebpay`,
        method: 'post',
        data: data
    })
}

function uploadImage(data) {
    return request({
        url: `/svcCustomer/uploadLogoImg`,
        method: 'post',
        data: data
    })
}

function addStore(companyId, data) {
    return request({
        url: `/svcCustomer/addStore?id=${companyId}`,
        method: 'post',
        data: data
    })
}


function updateStore(storeId, data) {
    return request({
        url: `/svcCustomer/updateStore?id=${storeId}`,
        method: 'post',
        data: data
    })
}


function queryStore(storeId) {
    return request({
        url: `/svcCustomer/queryStore?id=${storeId}`,
        method: 'get',
    })
}

  /**中控中心＿用戶管理＿藍新API串接金鑰_使用澈見代收預設值代入*/
  function queryNewebpayTypeLuciditv() {
    return request({
        url: `/svcCustomer/queryNewebpayTypeLuciditv`,
        method: 'get'
    })
}

export default {
    query,
    queryById,
    getCustomUbn,
    addCustomer,
    update,
    testExternalSysLogin,
    uploadImage,
    addStore,
    updateStore,
    queryStore,
    queryNewebpayTypeLuciditv,
    testNewebpay,
    testNewebpayStripe
}