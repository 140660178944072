import request from './request'

// 根據 商店channelType 帶出支付工具paymentTool[ OFFLINE / ONLINE ]
function getToolByChannelType(channelType) {
    return request({
        url: `/store/getToolByChannelType?channelType=${channelType}`,
        method: 'get'
    })
}

export default {
    getToolByChannelType,
}