import request from './request'



function getDonateOrgUnitByCode(code) {
  return request({
    url: `https://opendata.luciditv.com/donateOrgUnit/${code}`,
    method: 'get',
  })
}
function queryDonateOrgUnit(q) {
    return request({
      url: `https://opendata.luciditv.com/donateOrgUnit?q=${q}`,
      method: 'get',
    })
  }

export default {
    getDonateOrgUnitByCode,
    queryDonateOrgUnit
}