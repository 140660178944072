import auth from './auth'
import customer from './customer'
import request from './request'
import opendata from './opendata'
import store from './store'
import relation from './relation'
import plan from './plan'
import companyPlan from './companyPlan'
import account from './account'
import groupTeam from './groupTeam'
import functionGroup from './functionGroup'
export default {
  auth,
  request,
  customer,
  opendata,
  store,
  relation,
  plan,
  companyPlan,
  account,
  groupTeam ,
  functionGroup
}