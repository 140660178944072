import request from './request'

//全部關係顯示
function query(params) {
    return request({
    url: `/payerRelation/query`,
    method: 'post',
    data: params
  })
}

//編輯＿關係讀取
function show(id) {
    return request({
    url: `/payerRelation/show?id=${id}`,
    method: 'get',
   
  })
}

//關係新增
function addRelation(data) {
    return request({
    url: `/payerRelation/addRelation`,
    method: 'post',
    data: data
  })
}

//關係編輯
function updateRelation(id, data) {
    return request({
    url: `/payerRelation/updateRelation`,
    method: 'post',
    data: { id, ...data }
  })
}

//關係停用
function closeRelation(id) {
    return request({
    url: `/payerRelation/closeRelation?id=${id}`,
    method: 'get',
  })
}

//關係啟用
function openRelation(id) {
    return request({
    url: `/payerRelation/openRelation?id=${id}`,
    method: 'get',
  })
}

//關係發布到組織
function publishToAllCompany(id, data) {
    return request({
    url: `/payerRelation/publishToAllCompany`,
    method: 'post',
    data: { id, ...data }
  })
}




export default {
    query,
    show,
    addRelation,
    updateRelation,
    closeRelation,
    openRelation,
    publishToAllCompany,
}