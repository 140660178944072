import request from './request'

function getAccount() {
  return request({
    url: '/serviceAccount',
    method: 'get'
  })
}

//帳號查詢
function query(params) {
  return request({
    url: '/serviceAccount/query',
    method: 'post',
    data: params
  })
}

//新增帳號
function addAccount(data) {
  return request({
    url: '/serviceAccount/addAccount',
    method: 'post',
    data: data
  })
}

//帳號顯示
function queryById(id) {
  return request({
    url: `/serviceAccount/${id}`,
    method: 'get'
  })
}

//帳號編輯
function update(id, data) {
  return request({
    url: `/serviceAccount/updateApi?id=${id}`,
    method: 'post',
    data: data
  })
}

function accountStatusChange(id, params) {
  return request({
    url: `/serviceAccount/accountStatusChange?id=${id}`,
    method: 'post',
    data: params
  })
}


export default {
  getAccount,
  query,
  addAccount,
  queryById,
  update,
  accountStatusChange
}