import request from './request'

//方案查詢
function query(params) {
  return request({
    url: '/svcPlan/query',
    method: 'post',
    data: params
  })
}

//編輯＿方案讀取
function show(id) {
    return request({
    url: `/svcPlan/show?id=${id}`,
    method: 'get',
   
  })
}

//方案新增
function addPlan(data) {
    return request({
    url: `/svcPlan/addPlan`,
    method: 'post',
    data: data
  })
}

//方案編輯
function updatePlan(id, data) {
    return request({
    url: `/svcPlan/updatePlan`,
    method: 'post',
    data: { id, ...data }
  })
}





export default {
    query,
    show,
    addPlan,
    updatePlan,


    
}