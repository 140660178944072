import request from './request'
import Vue from 'vue'
import Utils from '../utils'

function login(params) {
  console.log(`auth login`)
  return request({
    url: '/api/login',
    method: 'post',
    data: params
  }).then(res => {
    let user = res.data
    Utils.Auth.login(user)
    return res
  }).catch(e => {
    return e
  })
}

function refreshToken() {
  let refreshToken = Vue.ls.get('refresh_token');
  return request({
    url: 'oauth/access_token',
    method: 'post',
    params: { "grant_type": "refresh_token", "refresh_token": refreshToken },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

function recaptchaVerify(params) {
  return request({
    url: '/recaptcha/verify',
    method: 'post',
    data: params
  }).then(res => {
    if (res.data.success && res.data.isLogin) {
      let user = res.data.user
      Utils.Auth.login(user)
    }
    return res
  }).catch(e => {
    return e
  })
}

export default {
  login,
  refreshToken,
  recaptchaVerify,
}
