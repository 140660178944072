import request from './request'

function query(params) {
  return request({
    url: '/serviceGroupTeam/query',
    method: 'post',
    data: params
  })
}

function getServiceFunctionAll() {
  return request({
    url: '/serviceGroupTeam/getServiceFunctionAll',
    method: 'get'
  })
}

function addServiceGroupTeam(data) {
  return request({
    url: '/serviceGroupTeam/addServiceGroupTeam',
    method: 'post',
    data: data
  })
}

function queryById(id) {
  return request({
    url: `/serviceGroupTeam/${id}`,
    method: 'get'
  })
}

function update(id, data) {
  return request({
    url: `/serviceGroupTeam/updateApi?id=${id}`,
    method: 'post',
    data: data
  })
}

function getAllServiceGroupTeam() {
  return request({
    url: '/serviceGroupTeam/getAllServiceGroupTeam',
    method: 'get'
  })
}

export default {
  query,
  getServiceFunctionAll,
  addServiceGroupTeam,
  queryById,
  update,
  getAllServiceGroupTeam,
}