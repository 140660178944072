import request from './request'


function getFunctionGroupAll() {
  return request({
    url: '/svcFunctionGroup/getFunctionGroup',
    method: 'get'
  })
}

export default {
    getFunctionGroupAll
  }