<template>
  <div
    class="c-app flex-row align-items-center"
    v-loading.fullscreen.lock="isLoading"
  >
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCard>
          <CCardBody class="p-4">
            <CCol md="12">
              <CForm>
                <CRow>
                  <CCol col="12" align="center">
                   <div style="heigt:20px">&nbsp;</div>
                    <h2>&nbsp;</h2>
                    <h2>
                      登入<span class="systemEnvironment-hint-word">{{
                        systemEnvironment == "local" ||
                        systemEnvironment == "dev"
                          ? "(測試環境)"
                          : ""
                      }}</span>
                    </h2>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="12" align="center">
                    <div style="padding: 30px; width: 350px;"></div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="12" class="text-left">
                    <CInput
                      placeholder="電子信箱"
                      autocomplete="username email"
                      v-model="email"
                    >
                      <template #prepend-content
                        ><CIcon name="cil-envelope-closed"
                      /></template>
                    </CInput>
                    <CInput
                      placeholder="密碼"
                      type="password"
                      v-model="password"
                    >
                      <template #prepend-content
                        ><CIcon name="cil-lock-locked"
                      /></template>
                    </CInput>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol col="12" class="text-left">
                    <div id="grecaptcha" style="margin-bottom: 10px;"></div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="12" align="center">
                    <div style="padding: 10px; width: 350px;"></div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="12" align="center">
                    <CButton
                      color="primary"
                      class="px-4"
                      v-on:click="recaptchaVerifyLogin"
                      >登入</CButton
                    >
                  </CCol>
                </CRow>
              </CForm>
            </CCol>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody class="p-4">
            <CRow>
              <CCol col="12" align="center">
                <div style="heigt:20px">&nbsp;</div>
                <h2>樂易捐</h2>
                <h2>中控中心</h2>
                <div class="tupian">
                  <!-- <img src="@/assets/images/sendForgotPassword.jpg" /> -->
                  <lottie-player
                    src="https://assets1.lottiefiles.com/packages/lf20_26ewjioz.json"
                    mode="bounce"
                    background="rgba(0, 0, 0, 0)"
                    speed="2"
                    style="width: 400px; height: 400px;"
                    loop
                    autoplay
                  ></lottie-player>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CRow>
    </CContainer>
    <!-- <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="電子信箱"
                    autocomplete="username email"
                    v-model="email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-envelope-closed"
                    /></template>
                  </CInput>
                  <CInput placeholder="密碼" type="password" v-model="password">
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" v-on:click="login"
                        >Login</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <CButton color="light" variant="outline" size="lg">
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer> -->
  </div>
</template>

<script>
  import API from "../../api/";
  import Utils from "../../utils/";
  export default {
    name: "Login",
    data() {
      return {
        isLoading: false,
        checked: true,
        systemEnvironment: `${process.env.NODE_ENV}`,
        sitekey: "6LevBSAbAAAAAFPsJLbGo9utYqlOfSd7M6UQeW4j",
        recaptchaToken: null,
        email: "", //zhihao.xiao@luciditv.com
        password: "", //123456
      };
    },
    created() {
      this.defaultLocal();
      // this.loadAccount();
    },
    mounted() {
      this.grecaptchaLoaded();
    },
    methods: {
      // 開發 帳號預設帶入
      defaultLocal() {
        // 開發使用
        console.log(process.env.NODE_ENV);
        if (process.env.NODE_ENV == "local" || process.env.NODE_ENV == "dev") {
          this.email = "zhihao.xiao@luciditv.com";
          this.password = "123456";
        }
      },

      // 上次儲存的帳號載入
      loadAccount() {
        let account = Utils.Auth.loadAccount();
        this.email = account.email;
      },

      // 清除之前本地儲存的帳號
      clearAccount() {
        Utils.Auth.clearAccount();
      },

      // 登入驗證
      // login() {
      //   this.isLoading = true;
      //   let params = {
      //     username: `${this.email}`,
      //     password: `${this.password}`,
      //   };
      //   //如果checked為true，紀錄這次的登入帳號
      //   if (this.checked === true) {
      //     let useraccount = { email: this.email };
      //     Utils.Auth.rememberAccount(useraccount);
      //   }
      //   API.auth
      //     .login(params)
      //     .then((res) => {
      //       this.$message.success("登入成功!");
      //       this.isLoading = false;
      //       this.$router.push({ path: "/" });
      //     })
      //     .catch((e) => {
      //       console.log(`login error`);
      //       this.$message.error("登入失敗!");
      //       this.isLoading = false;
      //     });
      // },

      // 機器人驗證後登入
      recaptchaVerifyLogin() {
        this.isLoading = true;
        let params = {
          token: `${this.recaptchaToken}`,
          username: `${this.email}`,
          password: `${this.password}`,
        };
        API.auth
          .recaptchaVerify(params)
          .then((res) => {
            if (res.data.success) {
              // this.login();
              // this.isLoading = false;
              if (res.data.isLogin) {
                this.$message.success("登入成功!");
                this.isLoading = false;
                this.$router.push({ path: "/" });
              } else {
                this.$message.error("登入失敗!");
                this.recaptchaToken = null;
                this.grecaptchaReset();
                this.isLoading = false;
              }
            } else {
              this.$message.error("機器人token，驗證失敗!");
              this.recaptchaToken = null;
              this.grecaptchaReset();
              this.isLoading = false;
            }
          })
          .catch((e) => {
            this.$message.error("機器人token，驗證失敗!");
            this.recaptchaToken = null;
            this.grecaptchaReset();
            this.isLoading = false;
          });
      },

      // 機器人驗證
      submit: function(token) {
        // console.log(token);
        this.recaptchaToken = token;
      },
      expiredCallback: function() {
        console.log("expiredCallback");
        this.recaptchaToken = null;
      },
      errorCallback: function() {
        console.log("errorCallback");
        this.recaptchaToken = null;
      },
      grecaptchaLoaded() {
        setTimeout(() => {
          window.grecaptcha.render("grecaptcha", {
            sitekey: this.sitekey,
            callback: this.submit,
            "expired-callback": this.expiredCallback,
            "error-callback": this.errorCallback,
          });
        }, 200);
      },
      grecaptchaReset() {
        setTimeout(() => {
          window.grecaptcha.reset();
        }, 200);
      },
    },
  };
</script>

<style scoped lang="scss">
  .logo {
    text-align: center;
    img {
      // width: 200px;
      height: 200px;
      margin-bottom: 60px;
      // CSS3 Gradients 漸層效果
      // 由上而下的線性漸層效果（預設值）
      // background: -webkit-linear-gradient(yellow,red);
      // background: -o-linear-gradient(yellow,red);
      // background: -moz-linear-gradient(yellow,red);
      // background: linear-gradient(yellow,red);
      // 對角線的線性漸層效果（由左下向右上）
      background: -webkit-linear-gradient(left top, yellow, red);
      background: -o-linear-gradient(bottom right, yellow, red);
      background: -moz-linear-gradient(bottom right, yellow, red);
      background: linear-gradient(to bottom right, yellow, red);
    }
  }

  .tupian {
    width: 400px;
    height: 400px;
  }
  .tupian img {
    width: 400px;
    height: 400px;
  }
  .systemEnvironment-hint-word {
    font-size: large;
    color: #fa8857;
  }
</style>
