import request from './request'


//組織方案清單
function getPlanList(id) {
  return request({
    url: `/svcCompanyPlan/getPlanList?planId=${id}`,
    method: 'get',
  })
}






export default {
  getPlanList,
 
  
}
